import {
	Goals
} from "../../Components";

export default function Home(): JSX.Element {
	return (
		<>
			<h1>Home</h1>
			<Goals />
		</>
	);
}
